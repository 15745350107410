import Page404 from 'components/404/404';
import MetaTags from 'components/layout/MetaTags';
import { DEFAULT_META_DATA } from 'domain/constants';

export default function Custom404() {
  return (
    <>
      <MetaTags
        title="Сторінки не знайдено"
        description="Сторінки не знайдено"
        imageUrl={DEFAULT_META_DATA.imageUrl}
      />

      <Page404 />
    </>
  );
}

import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import Image from 'next/image';

import background from '../../public/images/404-background.png';
import CustomA from 'components/ui/links/CustomA';

const Page404: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Image src={background.src} layout="fill" objectFit="cover" quality={100} />

      <div className={classes.content}>
        <Typography variant="h1" className={classes.title}>
          УПС, ЗДАЄТЬСЯ ЩОСЬ ПІШЛО НЕ ТАК
        </Typography>

        <Typography variant="h1" component="h2" className={classes.errorNumber}>
          404
        </Typography>

        <div className={classes.bottomContent}>
          <Typography variant="h1" component="h3" className={classes.subtitle}>
            СТОРІНКУ НЕ ЗНАЙДЕНО
          </Typography>

          <CustomA href="/">
            <Button size="large" variant="contained" color="primary">
              На головну
            </Button>
          </CustomA>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    position: 'relative'
  },
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '90%'
  },
  title: {
    color: theme.palette.info.main,
    textAlign: 'center'
  },
  errorNumber: {
    fontSize: '30vw',
    color: theme.palette.info.main,
    WebkitTextStroke: `${6}px ${theme.palette.common.white}`,
    [theme.breakpoints.up('md')]: {
      fontSize: 280,
      WebkitTextStroke: `${9}px ${theme.palette.common.white}`
    }
  },
  bottomContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(5),
      flexDirection: 'row'
    }
  },
  subtitle: {
    color: theme.palette.common.white,
    textAlign: 'center'
  }
}));

export default Page404;
